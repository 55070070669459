import request from '@/service/request'
import store from '@/store'

//物流api
export function list(data = {}) {
  return request({
    url: '/externaladmin/systemService/expressCompany/list/',
    method: 'post',
    data
  })
}

export async function add(data) {
  // eslint-disable-next-line no-undef
  return refreshDic(
    request({
      url: '/externaladmin/systemService/expressCompany/complexCreate',
      method: 'post',
      data
    }),
    'expressCompanyId'
  )
}

export function detail(data) {
  return request({
    url: `api/order/fedex/${data}`,
    method: 'get',
    data
  })
}

export async function edit(id, data) {
  data.id = id
  // eslint-disable-next-line no-undef
  return refreshDic(
    request({
      url: '/externaladmin/systemService/expressCompany/complexUpdate',
      method: 'post',
      data
    }),
    'expressCompanyId'
  )
}

export async function del(data) {
  // eslint-disable-next-line no-undef
  return refreshDic(
    request({
      url: '/externaladmin/systemService/expressCompany/delete',
      method: 'post',
      data
    }),
    'expressCompanyId'
  )
}

// 物流指定原型
export function assignPrototype(data = {}) {
  return request({
    url: '/externaladmin/logisticsService/expressAccountShippingMethod/assignPrototype',
    method: 'post',
    data
  })
}

// 获取已指定的原型
export function getAppointList(data = {}) {
  return request({
    url: '/externaladmin/logisticsService/expressPrototypeAssociation/list',
    method: 'post',
    data
  })
}
