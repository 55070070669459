import request from '@/service/request'
import { getXMenuType } from '@/utils/constant'

//=====图片分组======

//图片分组列表
export function getPictureGroupList(data, menuType = 'menu') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/productService/imageGroup/list',
    method: 'post',
    data
  })
}

//图片分组创建
export function createPictureGroup(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/productService/imageGroup/create',
    method: 'post',
    data
  })
}

//图片分组编辑
export function updatePictureGroup(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/productService/imageGroup/update',
    method: 'post',
    data
  })
}

//图片分组详情
export function getPictureGroupDetail(data, menuType = 'other') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/productService/imageGroup/detail',
    method: 'post',
    data
  })
}

//图片分组启用
export function enablePictureGroup(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/productService/imageGroup/enable',
    method: 'post',
    data
  })
}

//图片分组禁用
export function disablePictureGroup(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/productService/imageGroup/disable',
    method: 'post',
    data
  })
}
