import request from '@/service/request'
import { getXMenuType } from '@/utils/constant'

//=====原型分组======

//原型分组列表
export function getProtoGroupList(data, menuType = 'menu') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/productService/prototypeGroup/list',
    method: 'post',
    data
  })
}

//原型分组创建
export function createProtoGroup(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/productService/prototypeGroup/create',
    method: 'post',
    data
  })
}

//原型分组编辑
export function updateProtoGroup(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/productService/prototypeGroup/update',
    method: 'post',
    data
  })
}

//原型分配组添加原型
export function addPrototypeUpdate(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/productService/prototypeGroup/addPrototypeUpdate',
    method: 'post',
    data
  })
}

//原型分组详情
export function getProtoGroupDetail(data, menuType = 'other') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/productService/prototypeGroup/detail',
    method: 'post',
    data
  })
}

//原型分组启用
export function enableProtoGroup(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/productService/prototypeGroup/enable',
    method: 'post',
    data
  })
}

//原型分组禁用
export function disableProtoGroup(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/productService/prototypeGroup/disable',
    method: 'post',
    data
  })
}

//原型组分配分销商
export function assignGroup(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/authService/user/groupDistributionSet',
    method: 'post',
    data
  })
}
